import { useTranslation } from 'next-i18next';
import React from 'react';
import Link from 'next/link';
import { REGISTRATION_STATUS, Serie } from '@root/src/types';
import routeList from '@services/routeList';
import { Tag, TagContainer } from '@features/serieDetail/components/SerieCard/styles';

export enum TAGS {
  ALL = 'ALL',
  REGISTRATION_OPEN = 'REGISTRATION_OPEN',
}

export const mapTagToTranslationKey = {
  [TAGS.ALL]: 'tags_all.message',
  [TAGS.REGISTRATION_OPEN]: 'tags_registrationsOpen.message',
};

const useSerieInfo = (serie: Serie) => {
  const { t } = useTranslation();

  const areRegistrationOpen = serie.status.registration === REGISTRATION_STATUS.OPEN;
  const areRegistrationClosed = serie.status.registration === REGISTRATION_STATUS.CLOSED;

  const mapTagToUrl = { [TAGS.REGISTRATION_OPEN]: routeList.series(serie.slug).subscriptions() };

  const tags = [areRegistrationOpen && TAGS.REGISTRATION_OPEN]
    .filter(Boolean) as string[];

  const allTags = tags.map(tag => t(mapTagToTranslationKey[tag]));

  const SimpleTag = ({ tag }) => (
    <Tag>
      {t(mapTagToTranslationKey[tag])}
    </Tag>
  );

  const generateTags = () => tags.map(tag => (
    <TagContainer key={tag} onClick={(e) => e.stopPropagation()}>
      <Link href={mapTagToUrl[tag]}>
        <a>
          <SimpleTag tag={tag} />
        </a>
      </Link>
    </TagContainer>
  ));

  return {
    areRegistrationOpen,
    areRegistrationClosed,
    allTags,
    generateTags,
  };
};

export default useSerieInfo;
