import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { RESPONSIVE, useResize, useResponsive } from '@services/responsiveProvider';
import { selectSponsoredEvents } from '@features/eventList/slices/eventListServerSliceState';
import { textH2 } from '@features/ui/styles/textStyles';
import Carousel from '@features/ui/components/Carousel';
import SponsoredEventsCard from '@features/home/components/SponsoredEventsCard';

interface SponsoredEventsBlockProps {
  className?: string;
}

const SponsoredEventsBlock = ({ className }: SponsoredEventsBlockProps) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  const events = useSelector(selectSponsoredEvents);

  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const updateContainerWidth = () => {
    const element = containerRef.current as Element;
    if (element == null) return;
    setContainerWidth(element.getBoundingClientRect().width);
  };

  useResize(updateContainerWidth);

  return (
    <Container className={className} ref={containerRef}>
      <Header>
        <Title>{t('sponsoredEventsBlock_title.message')}</Title>
      </Header>

      <CarouselContainer>
        <Carousel
          withNavigation={responsive === RESPONSIVE.DESKTOP}
          withDots
          infinite
          rotationTimeout={5000}
        >
          {events.map(e => (
            <SponsoredEventsCard
              id={e._idMso}
              containerWidth={containerWidth}
            />
          ))}
        </Carousel>
      </CarouselContainer>
    </Container>
  );
};

export default SponsoredEventsBlock;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  ${textH2};
  flex: 1;
  margin-left: 9px;
`;

const CarouselContainer = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
  .CurrentEventsCard_Link {
    display: flex;
  }
`;
