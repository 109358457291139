import config from '@root/src/config';

type PageMetaProps = {
  title: string;
  description: string;
  image?: string;
  openGraph?: boolean;
};

export const PageMeta = ({
  title,
  description,
  image,
  openGraph = true,
}: PageMetaProps) => (
  <>
    <title>{title}</title>
    <meta name="description" content={description} />
    {openGraph && (
      <>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {image && <meta property="og:image" content={config.urlBase + image} />}
      </>
    )}
  </>
);
