import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Link from 'next/link';
import { Event } from '@root/src/types';
import useEventInfo from '@root/src/hooks/useEventInfo';
import { formatDateAsYear, formatEventRangeFull } from '@utils/dates';
import routeList from '@services/routeList';
import { selectEventById } from '@features/eventList/slices/eventListServerSliceState';
import { textH4, textH3, textSmall } from '@features/ui/styles/textStyles';
import Icon from '@features/ui/components/Icon';
import { sportIconMapping } from '@features/eventList/components/EventCard';
import StackableCard from '@features/ui/components/StackableCard';

interface CurrentEventsCardProps {
  className?: string;
  id: number;
}

const CurrentEventsCard = ({
  id,
  className,
}: CurrentEventsCardProps) => {
  const event = useSelector(selectEventById(id)) as Event;
  const router = useRouter();
  const { generateTags } = useEventInfo(event);

  return (
    <Link href={routeList.events(event.slug).description()}>
      <a className="CurrentEventsCard_Link">
        <StyledStackableCard className={className}>
          <Icon name={sportIconMapping[event.sportCategory]} size="36px" />
          <Title>{`${event.name}`}</Title>
          <Year>{`${formatDateAsYear(event.date)}`}</Year>
          <Date>{formatEventRangeFull(event.date, event.dateFrom, event.dateTo, router.locale)}</Date>
          <TagsWrapper>
            { generateTags() }
          </TagsWrapper>
        </StyledStackableCard>
      </a>
    </Link>
  );
};

export default CurrentEventsCard;

const StyledStackableCard = styled(StackableCard)`
  min-height: 196px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 214px;
`;

const Title = styled.h4`
  ${textH4};
  margin-top: 12px;
`;

const Year = styled.h5`
  ${textH3};
  color: var(--color-grey4);
  margin: 0;
`;

const Date = styled.p`
  ${textSmall};
  color: var(--color-grey3);
  flex: 1;
  margin-bottom: 6px;
`;

const TagsWrapper = styled.div`
  > *:not(:first-child) {
    margin-top: 6px;
  }
`;
