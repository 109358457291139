import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import moment from 'moment';
import { QUERY_API_DATE_FORMAT_MOMENT } from '@utils/dates';
import { mediaBreakpoint, RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { textInputHigher, textInputHigherSemiBold } from '@features/ui/styles/textStyles';
import Button from '@features/ui/components/Button';
import DateRange from '@features/ui/components/DateRange';
import EventsSuggestions from '@features/home/components/EventsSuggestions';

interface FindEventsBarInputs {
  search: string,
  startDate: string,
  endDate: string,
}

const FindEventsBar = () => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  const router = useRouter();

  const initialValues: FindEventsBarInputs = {
    search: '',
    startDate: '',
    endDate: '',
  };

  const handleSubmit = async (values: FindEventsBarInputs) => {
    const {
      search,
      startDate,
      endDate,
    } = values;
    const isStartDateValid = moment.isDate(startDate);
    const isEndDateValid = moment.isDate(endDate);

    await router.push(getRoute.events().list({
      search,
      ...(isStartDateValid ? {} : { startDate }),
      ...(isEndDateValid ? {} : { endDate }),
    }));
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
        }) => (
          <StyledForm>
            <InputsWrapper>
              <EventsSuggestions apiParams={
                {
                  filteredRange: [
                    {
                      id: 'date',
                      type: 'date',
                      valueGte: values.startDate,
                      valueLte: values.endDate,
                    },
                  ],
                }
              }
              />
              {responsive === RESPONSIVE.DESKTOP && (
                <StyledDateRange
                  initialStartDate={values.startDate}
                  initialEndDate={values.endDate}
                  onDateChanged={({
                    startDate,
                    endDate,
                  }) => {
                    setFieldValue('startDate', startDate?.format?.(QUERY_API_DATE_FORMAT_MOMENT) ?? '');
                    setFieldValue('endDate', endDate?.format?.(QUERY_API_DATE_FORMAT_MOMENT) ?? '');
                  }}
                  customIconInput={<Icon name={ICON_NAME.dateRange} color="var(--color-grey3)" />}
                />
              )}
            </InputsWrapper>
            <ButtonWrapper>
              {responsive === RESPONSIVE.MOBILE ? (
                <StyledButton leftIcon={ICON_NAME.search} type="submit" />
              ) : (
                <StyledButton text={t('findEventsBar_search.message')} type="submit" />
              )}
            </ButtonWrapper>
          </StyledForm>
        )}

      </Formik>
    </Container>
  );
};

export default FindEventsBar;

const Container = styled.div`
  width: 100%;
  background-color: var(--color-white);
  height: 90px;
  box-sizing: border-box;
  border-radius: 28px;
  box-shadow: 0 2px 12px 0 rgba(128, 122, 122, 0.2);
  display: flex;
  align-items: center;

  ${mediaBreakpoint.tablet} {
    height: 60px;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
`;

const InputsWrapper = styled.div`
  margin: 0 40px;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;


  & input {
    background-color: unset;
    border: unset;
    ${textInputHigher};
  }

  & input:hover {
    background-color: unset;
  }

  & input:focus {
    background-color: unset;
  }

  ${mediaBreakpoint.tablet} {
    margin: 0 20px;
  }
`;

const StyledButton = styled(Button)`
  border-radius: 0 28px 28px 0;
  width: 214px;
  border-width: 0;
  margin: 0;

  & > :first-child {
    ${textInputHigherSemiBold};
  }

  ${mediaBreakpoint.mobile} {
    width: 78px;
  }
`;

const ButtonWrapper = styled.div`
  height: 100%;
  display: flex;
`;

const StyledDateRange = styled(DateRange)`
  display: inline-flex;
  align-items: center;
  max-width: 368px;

  .DateInput {
    input {
      background: var(--color-white);
      border-radius: 4px;
      height: 42px;
      ${textInputHigher};
      color: var(--color-grey4);
    }
  }

  .DateInput_input::placeholder {
    color: var(--color-grey3);
  }
`;
