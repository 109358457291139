import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Link from 'next/link';
import { formatEventRangeFull } from '@root/src/utils/dates';
import { Serie } from '@root/src/types';
import useSerieInfo from '@root/src/hooks/useSerieInfo';
import routeList from '@services/routeList';
import { selectEventById } from '@features/eventList/slices/eventListServerSliceState';
import { textH4, textH3, textSmall } from '@features/ui/styles/textStyles';
import Icon from '@features/ui/components/Icon';
import { sportIconMapping } from '@features/eventList/components/EventCard';
import StackableCard from '@features/ui/components/StackableCard';
import { SerieLabelTopRight } from '@features/ui/components/SerieLabel';

interface CurrentSeriesCardProps {
  className?: string;
  id: number;
}

const CurrentSeriesCard = ({
  id,
  className,
}: CurrentSeriesCardProps) => {
  const router = useRouter();
  const serie = useSelector(selectEventById(id)) as Serie;
  const { generateTags } = useSerieInfo(serie);

  return (
    <Link href={routeList.series(serie.slug).description()}>
      <a className="CurrentEventsCard_Link">
        <StyledStackableCard className={className}>
          <Icon name={sportIconMapping[serie.serieCategory]} size="36px" />
          <Title>{serie.name}</Title>
          <Year>{serie.year}</Year>
          <Date>
            {formatEventRangeFull(
              serie.serieDates?.earliestEventDate,
              serie.serieDates?.earliestEventDate,
              serie.serieDates?.latestEventDate,
              router.locale
            )}
          </Date>
          <TagsWrapper>
            { generateTags() }
          </TagsWrapper>
          <SerieLabelContainer>
            <SerieLabelTopRight />
          </SerieLabelContainer>
        </StyledStackableCard>
      </a>
    </Link>
  );
};

export default CurrentSeriesCard;

const StyledStackableCard = styled(StackableCard)`
  position: relative;
  min-height: 196px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 214px;
`;

const Title = styled.h4`
  ${textH4};
  margin-top: 12px;
`;

const Year = styled.h5`
  ${textH3};
  color: var(--color-grey4);
  margin: 0;
`;

const Date = styled.p`
  ${textSmall};
  color: var(--color-grey3);
  flex: 1;
  margin-bottom: 6px;
`;

const TagsWrapper = styled.div`
  > *:not(:first-child) {
    margin-top: 6px;
  }
`;

const SerieLabelContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
