import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mediaBreakpoint, useResize } from '@services/responsiveProvider';
import { ICON_NAME } from '@features/ui/components/Icon';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';

interface CarouselProps {
  withDots?: boolean,
  withNavigation?: boolean,
  infinite?: boolean,
  rotationTimeout?: number;
  startAtElement?: number;
  children: React.ReactNode[];
}

const Carousel = ({
  withDots,
  withNavigation,
  infinite,
  rotationTimeout,
  startAtElement,
  children,
}: CarouselProps) => {
  const numberOfElements = children.length;
  const childrenRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [numberOfDots, setNumberOfDots] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const elementWidth = useRef(0);

  const showNavigation = withNavigation && numberOfDots > 1;

  const initNumberOfDots = () => {
    const element = childrenRef.current as Element;
    if (element == null) return;
    const bodyWidth = element.scrollWidth;
    setNumberOfDots(Math.ceil(bodyWidth / element.getBoundingClientRect().width));
    elementWidth.current = bodyWidth / numberOfElements;
  };

  const initSlideWidth = () => {
    const element = childrenRef.current as Element;
    if (element == null) return;
    const bodyWidth = element.scrollWidth;
    setSlideWidth(Math.round(bodyWidth / numberOfDots));
  };

  const scrollUpdated = (e) => {
    const target = e.target as HTMLElement;
    if (target == null) return;
    if (target.scrollLeft === target.scrollWidth - target.offsetWidth) {
      setCurrentSlide(numberOfDots - 1);
    } else {
      const position = Math.floor(target.scrollLeft / slideWidth);
      setCurrentSlide(position);
    }
  };

  const initScrollListener = () => {
    const element = childrenRef.current as Element;
    if (element == null) return;
    element.addEventListener('scroll', scrollUpdated);
  };

  const moveToSlide = (position: number) => {
    const element = childrenRef.current as Element;
    if (element == null) return;
    element.scrollTo({
      left: position * slideWidth,
      behavior: 'smooth',
    });
    setCurrentSlide(position);
  };

  const goBack = () => {
    const slidePos = currentSlide - 1;

    if (slidePos < 0) {
      const newPos = infinite ? ((currentSlide - 1) % numberOfDots) + numberOfDots : 0;
      moveToSlide(newPos);
    } else {
      moveToSlide(slidePos);
    }
  };

  const goForward = () => {
    const slidePos = currentSlide + 1;

    if (slidePos > (numberOfDots - 1)) {
      const newPos = infinite ? (currentSlide + 1) % numberOfDots : numberOfDots - 1;
      moveToSlide(newPos);
    } else {
      moveToSlide(slidePos);
    }
  };

  let timer = null;
  useEffect(() => {
    if (rotationTimeout && numberOfDots > 0 && slideWidth > 0) {
      timer = setInterval(goForward, rotationTimeout);
    }

    return () => clearInterval(timer);
  }, [numberOfDots, slideWidth, currentSlide]);

  useEffect(() => {
    if (childrenRef.current) {
      initNumberOfDots();
    }
  }, [childrenRef.current]);

  useEffect(() => {
    initSlideWidth();
  }, [numberOfDots]);

  useEffect(() => {
    initScrollListener();
    return () => {
      const element = childrenRef.current as Element;
      element?.removeEventListener('scroll', scrollUpdated);
    };
  }, [slideWidth]);

  useEffect(() => {
    if (!startAtElement
      || numberOfDots === 0
      || numberOfElements === 0
      || !Number.isFinite(slideWidth)
    ) {
      return;
    }

    // Because we can't predictably know the number of elements per slide,
    // we calculate the starting slide based on elements widths
    const elementWidthPosition = (startAtElement + 1) * elementWidth.current;
    const startAtSlide = Math.floor(elementWidthPosition
      / (childrenRef.current as Element).getBoundingClientRect().width);

    moveToSlide(startAtSlide);
  }, [
    startAtElement,
    numberOfElements,
    numberOfDots,
    slideWidth,
  ]);

  useResize(() => {
    initNumberOfDots();
  });

  return (
    <Container>
      <Body>
        {showNavigation && (
          <Navigation side="left" onClick={goBack}>
            <Button
              leftIcon={ICON_NAME.chevronRight}
              disabled={!infinite && currentSlide === 0}
              variant={BUTTON_VARIANT.link}
              iconSize="60px"
            />
          </Navigation>
        )}
        <Content ref={childrenRef}>
          {children}
        </Content>
        {showNavigation && (
          <Navigation side="right" onClick={goForward}>
            <Button
              leftIcon={ICON_NAME.chevronLeft}
              disabled={!infinite && currentSlide === numberOfDots - 1}
              variant={BUTTON_VARIANT.link}
              iconSize="60px"
            />
          </Navigation>
        )}
      </Body>
      {(withDots && numberOfDots > 1) && (
        <Footer>
          {[...Array(numberOfDots)].map((_, index) => (
            <Dot
              key={`slide-${numberOfElements - index}}`}
              isActive={index === currentSlide}
              onClick={() => moveToSlide(index)}
            />
          ))}
        </Footer>
      )}
    </Container>
  );
};

export default Carousel;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 60px;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  ${mediaBreakpoint.tablet}{
    overflow: scroll;
  }
`;

const Navigation = styled.div<{ side: 'left' | 'right' }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);

  ${({ side }) => side === 'left' && 'left: -117px;'};
  ${({ side }) => side === 'right' && 'right: -117px'};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Dot = styled.div<{ isActive: boolean }>`
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: ${({ isActive }) => (isActive ? 'var(--color-orange)' : 'var(--color-grey3)')};
  align-items: center;
  cursor: pointer;
  margin-top: 20px;

  transition: all 0.2s;


  & + & {
    margin-left: 4px;
  }
`;
