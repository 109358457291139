import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';
import { mediaBreakpoint, RESPONSIVE, useResponsive } from '@root/src/services/responsiveProvider';
import config from '@root/src/config';
import getRoute from '@root/src/services/routeList';
import { formatEventDate } from '@utils/dates';
import { selectSponsoredEventById } from '@features/eventList/slices/eventListServerSliceState';
import { textH2, textP, textSmall } from '@features/ui/styles/textStyles';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import { sportIconMapping } from '@features/eventList/components/EventCard';
import StackableCard from '@features/ui/components/StackableCard';
import Button, { OwnProps } from '@features/ui/components/Button';
import { formatFullLocation } from '@features/eventDetail/utils';

interface SponsoredEventsCardProps {
  className?: string;
  id: number;
  containerWidth: number;
}

const SponsoredEventsCard = ({
  id,
  className,
  containerWidth,
}: SponsoredEventsCardProps) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  const {
    name,
    date,
    dateFrom,
    dateTo,
    location,
    site,
    sportCategory,
    imageCover,
    slug,
  } = useSelector(selectSponsoredEventById(id));

  const imageCoverUrl = imageCover
    ? config.urlBackendAssets + imageCover
    : '/images/mso-home.jpg';

  return (
    <SponsoredEventsCardContainer containerWidth={containerWidth}>
      <StyledStackableCard className={className}>
        <Icon name={sportIconMapping[sportCategory]} size="36px" />
        <SponsoredLabel>{t('sponsoredEventsBlock_captionSponsoredEvents.message')}</SponsoredLabel>
        <Title>{name}</Title>
        <CardLine>
          <Icon name={ICON_NAME.dateRange} color="var(--color-grey3)" />
          <span>{formatEventDate(date, dateFrom, dateTo)}</span>
        </CardLine>
        <CardLine>
          <Icon name={ICON_NAME.pin} color="var(--color-grey3)" />
          <span>{formatFullLocation(location, site)}</span>
        </CardLine>
        <StyledButton
          url={getRoute.events(slug).subscriptions()}
          leftIcon={ICON_NAME.playlistAddCheck}
          text={t('sponsoredEventsBlock_buttonRegistrationsOpen.message')}
          urlScroll={false}
        />
      </StyledStackableCard>

      {responsive !== RESPONSIVE.MOBILE && <ImageCover imageCoverUrl={imageCoverUrl} />}
    </SponsoredEventsCardContainer>
  );
};

export default SponsoredEventsCard;

const SponsoredEventsCardContainer = styled.div<{containerWidth: number}>`
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  
  margin: 17px;
  width: ${({ containerWidth }) => `${containerWidth - 34}px;`}
`;

const StyledStackableCard = styled(StackableCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 320px;
  margin-top: 30px;
  margin-right: -50px;
  min-height: 300px;
  padding: 20px 40px;

  z-index: var(--z-index-over-content);

  ${mediaBreakpoint.tablet}{
    margin-right: -250px;
  }
  ${mediaBreakpoint.mobile}{
    margin-right: 0;
  }
`;

const Title = styled.h4`
  ${textH2};
  margin-top: 12px;
  text-align: center;
`;

const SponsoredLabel = styled.p`
  ${textSmall}
  text-transform: uppercase;
  color: var(--color-grey3);
`;

const CardLine = styled.p`
  display: flex;
  gap: 10px;
  align-items: center;

  ${textP}
  margin-top: 12px;
`;

const StyledButton = styled(Button)<OwnProps>`
  margin-top: 12px;
`;

const ImageCover = styled.div<{imageCoverUrl: string}>`
  flex: 1 0 auto;
  min-height: 420px;

  background-image: url(${({ imageCoverUrl }) => imageCoverUrl});
  background-size:cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;
