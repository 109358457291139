import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import FindEventsBlock from '@features/home/components/FindEventsBlock';
import CurrentEventsBlock from '@features/home/components/CurrentEventsBlock';
import ExploreEventsBlock from '@features/home/components/ExploreEventsBlock';
import { selectSponsoredEvents } from '@features/eventList/slices/eventListServerSliceState';
import SponsoredEventsBlock from '@features/home/components/SponsoredEventsBlock';

const HomeTemplate = () => {
  const events = useSelector(selectSponsoredEvents);

  return (
    <>
      <FindEventsBlock />
      <ContentWrapper>
        <CurrentEventsBlock />

        {events?.length > 0 && <StyledSponsoredEventsBlock />}

        <StyledExploreEventsBlock />
      </ContentWrapper>
    </>
  );
};

export default HomeTemplate;

const ContentWrapper = styled.div`
  padding: 40px 117px 0;

  ${mediaBreakpoint.tablet} {
    padding: 40px 0 0;
  }
`;

const StyledSponsoredEventsBlock = styled(SponsoredEventsBlock)`
  margin-top: 60px;
`;

const StyledExploreEventsBlock = styled(ExploreEventsBlock)`
  margin-top: 60px;
`;
