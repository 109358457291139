import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import { mediaBreakpoint, RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import getRoute from '@services/routeList';
import { textTitle } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import { ICON_NAME } from '@features/ui/components/Icon';
import { selectNumberOfEventsToExplore } from '@features/eventList/slices/eventListServerSliceState';
import FindEventsBar from '@features/home/components/FindEventsBar';

const FindEventsBlock = () => {
  const { t } = useTranslation();
  const responsive = useResponsive();
  const numberOfEventsToExplore = useSelector(selectNumberOfEventsToExplore);

  return (
    <Container>
      <ImageWrapper>
        <picture>
          <source
            sizes="
              (max-width: 750px) 640px,
              (max-width: 1080px) 750px,
              (max-width: 1200px) 1080px,
              (max-width: 1920px) 1200px,
               1920px
            "
            srcSet="
              /images/home/640-427.webp 640w,
              /images/home/750-500.webp 750w,
              /images/home/1080-720.webp 1080w,
              /images/home/1200-800.webp 1200w,
              /images/home/1920-1280.webp 1920w
             "
            type="image/webp"
          />
          <StyledImage
            src="/images/home/0-0.jpg"
            decoding="async"
            loading="lazy"
            alt="runner in the mountain"
            sizes="
              (max-width: 750px) 640px,
              (max-width: 1080px) 750px,
              (max-width: 1200px) 1080px,
              (max-width: 1920px) 1200px,
               1920px
            "
            srcSet="
              /images/home/640-427.jpg 640w,
              /images/home/750-500.jpg 750w,
              /images/home/1080-720.jpg 1080w,
              /images/home/1200-800.jpg 1200w,
              /images/home/1920-1280.jpg 1920w
            "
          />
        </picture>
      </ImageWrapper>
      <Content>
        <Title>{t('findEventsBlock_exploreTitle.message', { count: numberOfEventsToExplore })}</Title>
        <FindEventsBar />
        {responsive !== RESPONSIVE.MOBILE && (
          <ButtonWrapper>
            <Button
              url={getRoute.events().list({})}
              text={t('findEventsBlock_exploreCatalogue.message')}
              variant={BUTTON_VARIANT.linkSecondary}
              leftIcon={ICON_NAME.arrowForward}
            />
          </ButtonWrapper>
        )}
      </Content>
    </Container>
  );
};

export default FindEventsBlock;

const Container = styled.div`
  background: linear-gradient(180deg, rgba(32,50,51,0.5) 0%, rgba(0,0,0,0) 100%);
  width: 100vw;
  position: relative;
  left: calc(-50vw + 50%);
  margin-top: calc(var(--menu-height) * -1);
  display: flex;
  padding-top: 252px;
  padding-bottom: 170px;
  flex-direction: column;
  align-items: center;

  ${mediaBreakpoint.mobile} {
    padding-bottom: 70px;
  }
`;

const Content = styled.div`
  width: 100%;
  min-width: var(--page-min-width);
  max-width: var(--page-max-width);
  padding: 0 137px;

  ${mediaBreakpoint.tablet} {
    padding: 0 121px;
  }

  ${mediaBreakpoint.mobile} {
    padding: 0 20px;
  }

`;
const Title = styled.h1`
  ${textTitle};
  max-width: 448px;
  color: var(--color-white);
  margin-bottom: 60px;

  ${mediaBreakpoint.mobile} {
    margin-bottom: 30px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
const ImageWrapper = styled.div`
  z-index: var(--z-index-under-content);
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
`;
const StyledImage = styled.img`
  z-index: var(--z-index-under-content);
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-image: url("/images/home/0-0.jpg");
  background-position: center center;
  background-size: cover;
`;
