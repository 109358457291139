import React from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import ssrProvider from '@services/ssrProvider';
import ReduxWrapper from '@services/store';
import {
  GET_DATA_FOR,
  getServerSideData,
} from '@services/dataHandler/getPageData';
import { useRollbarContext } from '@services/rollbar';
import HomeTemplate from '@features/home/templates/HomeTemplate';
import { PageMeta } from '@features/ui/components/PageMeta';

const HomePage = () => {
  useRollbarContext('Home Page');
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <PageMeta
          title={`${t('head_homePageTitle.message')} ${t(
            'head_siteTitle.message'
          )}`}
          description={`${t('head_homePageTitle.message')} ${t(
            'head_siteTitle.message'
          )}`}
          image="/images/home/750-500.jpg"
        />
      </Head>
      <HomeTemplate />
    </>
  );
};

export default HomePage;

export const getServerSideProps = ReduxWrapper.getServerSideProps(
  (store) => async (serverSideContext) => ssrProvider({
    store,
    serverSideContext,
    process: async ({ store }) => {
      await getServerSideData(
        store,
        serverSideContext,
        GET_DATA_FOR.HOME,
      );
    },
  }),
);
