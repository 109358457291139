import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import config from '@root/src/config';
import { EVENT_LIST_ITEM_TYPES } from '@root/src/types';
import getRoute from '@services/routeList';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import { selectEventsIDsList } from '@features/eventList/slices/eventListServerSliceState';
import CurrentEventsCard from '@features/home/components/CurrentEventsCard';
import { textH2 } from '@features/ui/styles/textStyles';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import Carousel from '@features/ui/components/Carousel';
import { ICON_NAME } from '@features/ui/components/Icon';
import CurrentSeriesCard from './CurrentSeriesCard';

const CurrentEventsBlock = () => {
  const { t } = useTranslation();
  const eventsIds = useSelector(selectEventsIDsList(true));
  const responsive = useResponsive();
  return (
    <Container>
      <Header>
        <Title>{t('currentEventsBlock_title.message')}</Title>
        <Button
          url={getRoute.events().list({})}
          variant={BUTTON_VARIANT.link}
          text={t('currentEventsBlock_buttonSeeAll.message')}
          leftIcon={ICON_NAME.arrowForward}
        />
      </Header>
      <CarouselWrapper>
        {eventsIds.length > 0 && (
          <Carousel
            withNavigation={responsive === RESPONSIVE.DESKTOP}
            startAtElement={config.lists.currentEventsSize - 1}
            withDots
            infinite
          >
            {eventsIds.map(({ id, type }) => (type === EVENT_LIST_ITEM_TYPES.event
              ? <StyledCurrentEventCard key={`e-${id}`} id={id} />
              : <StyledCurrentSeriesCard key={`s-${id}`} id={id} />))}
          </Carousel>
        )}
      </CarouselWrapper>
    </Container>
  );
};

export default CurrentEventsBlock;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  ${textH2};
  flex: 1;
  margin-left: 9px;
`;

const StyledCurrentEventCard = styled(CurrentEventsCard)`
  margin: 17px;
  :first-child {
    margin-left: 9px;
  }
`;

const StyledCurrentSeriesCard = styled(CurrentSeriesCard)`
  margin: 17px;
  :first-child {
    margin-left: 9px;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 100%;
  .CurrentEventsCard_Link {
    display: flex;
  }
`;
