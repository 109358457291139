import styled, { css } from 'styled-components';
import { mediaBreakpoint } from '@services/responsiveProvider';
import Card from '@features/ui/components/Card';
import { textH4, textP } from '@features/ui/styles/textStyles';

export const columnsTemplate = css`
  grid-template-columns: 36px 1fr 80px 140px 140px 44px;
`;

export const Container = styled(Card)`
  position: relative;
  padding: 20px;
  display: grid;
  overflow: hidden;
  align-items: flex-start;
  ${columnsTemplate};
  grid-column-gap: 20px;
  ${mediaBreakpoint.mobile} {
      grid-template-columns: auto auto 1fr;
  }
  cursor: pointer;
`;

export const Section = styled.div<{ justiyContent?: 'flex-end' | 'flex-start' | 'center' }>`
  ${textP};
  color: var(--color-grey4);
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justiyContent }) => justiyContent ?? 'flex-start'};
  line-height: 30px;
`;

export const SectionNotVisibleOnMobile = styled(Section)`
  ${mediaBreakpoint.mobile} {
      display: none;
  }
`;

export const NameWrapper = styled.div`
  h4 {
      ${textH4};
      color: var(--color-dark);
  }
  display: flex;
  flex-direction: column;
`;

export const TagContainer = styled.div`
  a {
    display: inline-block;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  grid-column: 2 / 7;
  flex-wrap: wrap;
  margin-top: 14px;
  > *:not(:last-child) {
      margin-right: 4px;
      margin-bottom: 4px;
  }
`;

export const Tag = styled.div`
  background: var(--color-blue);
  color: var(--color-white);
  padding: 0 12px;
  ${textP};
  border-radius: 22px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  min-height: 24px;
`;

export const SportContainer = styled.div`
  display:flex;  
  flex-direction: column;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-grey4);
  >:first-child{
    margin-right: 8px; 
  }
  margin-top: 12px;
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const Timing = styled.span`
  display: block;
`;

export const SerieLabelContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;
