import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { SPORT_CATEGORIES } from '@root/src/types';
import getRoute from '@services/routeList';
import { RESPONSIVE, useResponsive } from '@services/responsiveProvider';
import { textH2, textH4 } from '@features/ui/styles/textStyles';
import Carousel from '@features/ui/components/Carousel';
import Button, { BUTTON_VARIANT } from '@features/ui/components/Button';
import Icon, { ICON_NAME } from '@features/ui/components/Icon';
import StackableCard from '@features/ui/components/StackableCard';
import { sportIconMapping } from '@features/eventList/components/EventCard';

interface ExploreEventsBlockProps {
  className?: string;
}

const ExploreEventsBlock = ({ className }: ExploreEventsBlockProps) => {
  const { t } = useTranslation();
  const responsive = useResponsive();

  return (
    <Container className={className}>
      <Header>
        <Title>{t('exploreEventsBlock_title.message')}</Title>
        <Button
          url={getRoute.events().list({})}
          variant={BUTTON_VARIANT.link}
          text={t('exploreEventsBlock_buttonSeeAll.message')}
          leftIcon={ICON_NAME.arrowForward}
        />
      </Header>
      <CarouselWrapper>
        <Carousel
          withDots
          infinite
          withNavigation={responsive === RESPONSIVE.DESKTOP}
        >
          {Object.keys(SPORT_CATEGORIES).map(category => (
            <Link key={category} href={getRoute.events().list({ sportCategory: SPORT_CATEGORIES[category] })}>
              <a className="SPORT_CATEGORIES_list_link">
                <StyledStackableCard>
                  <Icon name={sportIconMapping[SPORT_CATEGORIES[category]]} size="36px" />
                  <CategoryTitle>{t(`exploreEventsBlock_${SPORT_CATEGORIES[category]}.message`)}</CategoryTitle>
                </StyledStackableCard>
              </a>
            </Link>
          ))}
        </Carousel>
      </CarouselWrapper>
    </Container>
  );
};

export default ExploreEventsBlock;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  ${textH2};
  flex: 1;
  margin-left: 9px;
`;

const CarouselWrapper = styled.div`
  display: flex;
  justify-content: center;

  .SPORT_CATEGORIES_list_link {
    display: flex;
  }
`;

const StyledStackableCard = styled(StackableCard)`
  min-height: 132px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 154px;
  margin: 12px;
  ${textH4};
  align-items: center;

  :first-child {
    margin-left: 9px;
  }
`;

const CategoryTitle = styled.h4`
  flex: 1;
  display: inline-flex;
  align-items: center;
  text-align: center;
  margin-top: 8px;
`;
